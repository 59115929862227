import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      // main: '#bac3b8',
      // main: '#b7e3e5',
      // main: '#c9eaec',
      main: '#4d5358',
    },
    secondary: {
      // main: '#d7ccb8',
      main: '#bac3b8',
      // main: '#e3dfd6',
    },
  },
});

export default theme;
