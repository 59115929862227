import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';

export function About() {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 6 }}>
        <Typography variant="h2" align="center" component="h1" gutterBottom>
          Bemutatkozás
        </Typography>
      </Container>

      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}>
            <Box
              component="img"
              sx={{
                maxHeight: 500,
                objectFit: 'contain',
                objectPosition: { sm: 'center' },
                width: '100%',
                height: { xs: '100%', sm: 500, md: 400 },
              }}
              src="/assets/IMG_1496.jpg"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Stefán Gergely vagyok a szakmát 16 éves koromban kezdtem és azon
                szerencsések közé tartozom aki még a szüleitől tanulhatta ki a
                szakmát, pontosabban édesapámtól, és nagypapámtól tanultam.
                Édesapám Stefán Zoltán aki hosszú évtizedekig volt jelen az egri
                vállalkozói életben nyugdíjba vonulása után át vettem a
                vállalkozását. Tehát elmondhatjuk hogy közel 20 év szakmai
                tapasztalattal vállalunk szoba festést, azon belül festést,
                glettelés, tapétazást és mázolási munkálatokat tehát minden ami
                a festessel kapcsolatos. Továbbá vállalunk homlokzati
                szigetelést szigetelést és rendelkezek ennek a szakmai
                papirjával is. És hidegburkolast és gipszkartonozasi
                munkálatokat Eger és 60km vonzás körzetében és igény esetén
                távolabb is.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
