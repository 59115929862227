import './App.scss';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import Header from './layouts/Header';
import Main from './layouts/Main';
import Footer from './layouts/Footer';

import { Home } from './pages/Home';
import { Prices } from './pages/Prices';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Floorlaying } from './pages/Floorlaying';
import { Paint } from './pages/Paint';
import { Insulation } from './pages/Insulation';

import NotImplementedYet from './pages/NotImplementedYet';

import theme from './helpers/theme';
import Head from './components/common/Head';

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Head />

        <Header />

        <Main>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/szigeteles" element={<Insulation />} />
            <Route path="/festes" element={<Paint />} />
            <Route path="/burkolas" element={<Floorlaying />} />
            <Route path="/arak" element={<Prices />} />
            <Route path="/rolunk" element={<About />} />
            <Route path="/kapcsolat" element={<Contact />} />

            <Route path="*" element={<NotImplementedYet />} />
          </Routes>
        </Main>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
