import { Box, Divider, Typography } from '@mui/material';

function PriceTableV2() {
  return (
    <Box maxWidth="md" mx="auto">
      <Box>
        <Typography
          variant="h4"
          fontSize={30}
          fontWeight={300}
          align="center"
          component="h2"
        >
          Hőszigetelés
        </Typography>
        <Divider />
        <Box mt={2} mb={3}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Kompletten</Typography>
            <Typography fontWeight={500}>8500 Ft/m2-től</Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="h4"
          fontSize={30}
          fontWeight={300}
          align="center"
          component="h2"
        >
          Szobafestés
        </Typography>
        <Divider />
        <Box mt={2} mb={3}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Kétszeri festés</Typography>
            <Typography fontWeight={500}>1100 Ft - 1300 Ft/m2-től</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Kétszeri glettelés</Typography>
            <Typography fontWeight={500}>1600 Ft/m2-től</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Felület alapozás</Typography>
            <Typography fontWeight={500}>400 Ft/m2-től</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Tapétázás</Typography>
            <Typography fontWeight={500}>3000 Ft/m2-től</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Mázolás</Typography>
            <Typography fontWeight={500}>2500 Ft/m2-től</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Lazúrozás</Typography>
            <Typography fontWeight={500}>2500 Ft/m2-től</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Homlokzat festés</Typography>
            <Typography fontWeight={500}>2200 Ft/m2-től</Typography>
          </Box>
        </Box>
      </Box>

      <Typography fontWeight={300} mt={6} align="center">
        Az árak tájékoztató jellegűek. Pontos árajánlathoz helyszíni felmérés
        szükséges.
      </Typography>
    </Box>
  );
}

export default PriceTableV2;
