import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';

export function Contact() {
  return (
    <>
      <Container>
        <Box sx={{ py: 4 }}>
          <Typography variant="h2" align="center" component="h1" gutterBottom>
            Kapcsolat
          </Typography>
        </Box>
      </Container>
    </>
  );
}
