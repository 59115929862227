import React, { useState } from 'react';
import {
  ImageList,
  ImageListItem,
  Modal,
  IconButton,
  Box,
} from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

interface ImageGalleryProps {
  images: string[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleImageClick = (image: string, index: number) => {
    setSelectedImage(image);
    setCurrentIndex(index);
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const handlePrevImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <ImageList variant="standard" cols={4} gap={8}>
        {images.map((image, index) => (
          <ImageListItem
            key={index}
            onClick={() => handleImageClick(image, index)}
            sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
          >
            <img
              src={image}
              alt={`Reference ${index}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Modal
        open={!!selectedImage}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          outline: 'none',
        }}
      >
        <Box
          sx={{
            maxWidth: '90%',
            maxHeight: '90%',
            overflow: 'hidden',
            position: 'relative',
            outline: 'none',
          }}
        >
          <IconButton
            onClick={handlePrevImage}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '5%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              backgroundColor: grey[100],
              opacity: 0.5,
              ':hover': {
                opacity: 0.8,
                backgroundColor: grey[100],
              },
            }}
          >
            <NavigateBefore />
          </IconButton>

          <img
            src={selectedImage || ''}
            alt="Full-screen"
            style={{
              height: '100vh',
              width: '100vw',
              objectFit: 'contain',
              outline: 'none',
            }}
          />

          <IconButton
            onClick={handleNextImage}
            sx={{
              position: 'absolute',
              top: '50%',
              right: '5%',
              transform: 'translate(50%, -50%)',
              zIndex: 1,
              backgroundColor: grey[100],
              opacity: 0.5,
              ':hover': {
                opacity: 0.8,
                backgroundColor: grey[100],
              },
            }}
          >
            <NavigateNext />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default ImageGallery;
