import { Box, Container, Divider, Grid, Link, Typography } from '@mui/material';
import ServiceItem from '../components/ServiceItem';
import { grey } from '@mui/material/colors';
import SlideInBox from '../layouts/SlideInBox';
import ImageGallery from '../components/ImageGallery';

export function Home() {
  return (
    <>
      <Box>
        <Box
          component="div"
          sx={{
            backgroundImage: 'url(/assets/IMG_1639.jpg)',
            width: '100%',
            height: 'calc(100vh - 65px)',
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              bgcolor: '#4d5358',
              px: 5,
              py: 3,
              borderRadius: 2,
              opacity: 0.85,
            }}
          >
            <Typography
              variant="h2"
              align="center"
              component="h1"
              color={grey[100]}
            >
              Stefán Festés
            </Typography>
            <Typography
              variant="h5"
              align="center"
              component="h2"
              fontWeight={300}
              color={grey[100]}
            >
              Minden ami festés
            </Typography>
          </Box>
        </Box>
      </Box>

      <Container
        maxWidth="md"
        sx={{
          p: 6,
        }}
      >
        <Typography variant="h2" align="center" component="h1">
          Stefán Festés
        </Typography>
        <Typography
          variant="h5"
          align="center"
          component="h2"
          mb={3}
          fontWeight={300}
          color="text.secondary"
        >
          Minden ami festés
        </Typography>

        <Divider variant="middle" />

        <Typography
          variant="body1"
          align="center"
          color="text.secondary"
          my={3}
        >
          Kreativitás a színekben - Bízd ránk otthonod átalakítását, és
          garantáljuk, hogy a végeredmény nem csupán egy egyszerű festés lesz.
          Az általunk alkotott színek és minták életet lehelnek a térbe, és
          otthonoddá teszik.
        </Typography>
      </Container>

      <Divider variant="middle" />

      <Container maxWidth="md" sx={{ my: 6 }}>
        <Typography variant="h2" align="center" component="h1" gutterBottom>
          Szolgáltatások
        </Typography>

        <Grid
          spacing={2}
          justifyContent="space-between"
          display="flex"
          gap={2}
          sx={{
            '@media (max-width: 600px)': {
              flexDirection: 'column',
            },
            '@media (min-width: 601px)': {
              flexDirection: 'row',
            },
          }}
        >
          <Link
            href="/szigeteles"
            underline="none"
            sx={{ flex: '1', maxWidth: { sm: '100%', md: 345 } }}
          >
            <SlideInBox timeout={500}>
              <ServiceItem
                title="Hőszigetelés"
                description="A megfelelő hőszigetelés kiválasztása és telepítése a lakóépület 
              energiahatékonyságának és élettartamának növelését szolgálja, miközben 
              kényelmesebb és gazdaságosabb lakókörnyezetet teremt az ott élők számára."
                imageSrc="/assets/IMG_1496.jpg"
              />
            </SlideInBox>
          </Link>
          <Link
            href="/festes"
            underline="none"
            sx={{ flex: '1', maxWidth: { sm: '100%', md: 345 } }}
          >
            <SlideInBox timeout={900}>
              <ServiceItem
                title="Festés, gipszkartonozás"
                description="A szobafestés révén az épület belső terei frissebbé, 
              tágasabbnak és kellemesebbnek tűnnek. Emellett a festékek különböző színek és árnyalatok 
              széles skáláját kínálják, így az otthon hangulatát személyre szabhatjuk az ott élők 
              preferenciáinak megfelelően."
                imageSrc="/assets/images/festes/IMG_1846.webp"
              />
            </SlideInBox>
          </Link>
          <Link
            href="/burkolas"
            underline="none"
            sx={{ flex: '1', maxWidth: { sm: '100%', md: 345 } }}
          >
            <SlideInBox timeout={1300}>
              <ServiceItem
                title="Burkolás"
                description="A lakóépület burkolásának megfelelő tervezése és kivitelezése tehát 
              nemcsak esztétikai szempontból fontos, hanem a funkció és a tartósság szempontjából is. 
              Professzionális burkoló szakemberek bevonása segíthet a megfelelő"
                imageSrc="/assets/images/burkolas/IMG_2891.webp"
              />
            </SlideInBox>
          </Link>
        </Grid>
      </Container>

      <Divider variant="middle" />

      <ImageGallery
        images={[
          '/assets/IMG_1496.jpg',
          '/assets/IMG_1639.jpg',
          '/assets/images/szigeteles/IMG_1643.jpg',
          '/assets/images/szigeteles/IMG_1636.jpg',
        ]}
      />
    </>
  );
}
