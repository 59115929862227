import { Helmet } from 'react-helmet';

export default function Head() {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>Stefán festés</title>
      <meta name="title" content="Stefán festés" />
      <meta
        name="description"
        content="Szobafestéssel, hőszigeteléssel
        és burkolással foglalkozunk. 20 éves építőipari tapasztalattal Egerben és vonzáskörzetében"
      />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://stefanfestes.hu/" />
      <meta property="og:title" content="Stefán festés" />
      <meta
        property="og:description"
        content="Szobafestéssel, hőszigeteléssel
        és burkolással foglalkozunk. 20 éves építőipari tapasztalattal Egerben és vonzáskörzetében"
      />
      <meta
        property="og:image"
        content="https://stefanfestes.hu/assets/IMG_1496.jpg"
      />
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://stefanfestes.hu/" />
      <meta property="twitter:title" content="Stefán festés" />
      <meta
        property="twitter:description"
        content="Szobafestéssel, hőszigeteléssel
        és burkolással foglalkozunk. 20 éves építőipari tapasztalattal Egerben és vonzáskörzetében"
      />
      <meta
        property="twitter:image"
        content="https://stefanfestes.hu/assets/IMG_1496.jpg"
      />
      {/* Google / Search Engine Tags */}
      <meta itemProp="name" content="Stefán festés" />
      <meta
        itemProp="description"
        content="Szobafestéssel, hőszigeteléssel
        és burkolással foglalkozunk. 20 éves építőipari tapasztalattal Egerben és vonzáskörzetében."
      />
      <meta
        itemProp="image"
        content="https://stefanfestes.hu/assets/IMG_1496.jpg"
      />
    </Helmet>
  );
}
