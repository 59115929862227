import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';

const pages = {
  szigeteles: 'Hőszigetelés',
  festes: 'Festés',
  burkolas: 'Burkolás',
  arak: 'Áraink',
  rolunk: 'Rólunk',
  kapcsolat: 'Kapcsolat',
};

function Header() {
  const activeStyle: React.CSSProperties = {
    color: grey[500],
    backgroundColor: '#4d5358',
    fontWeight: 600,
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };

  const renderMobileMenu = () => (
    <Box
      sx={{ width: 250, backgroundColor: '#4d5358', height: '100%' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {Object.keys(pages).map((page) => (
          <NavLink to={page}>
            {({ isActive }) => (
              <ListItem
                key={page}
                disablePadding
                sx={{ '&:hover': { backgroundColor: grey[800] } }}
              >
                <ListItemButton
                  sx={{
                    py: 2,
                    color: grey[100],
                    display: 'block',
                    fontWeight: 400,
                  }}
                  component={NavLink}
                  to={page}
                  style={isActive ? activeStyle : undefined}
                >
                  <ListItemText primary={pages[page as keyof typeof pages]} />
                </ListItemButton>
              </ListItem>
            )}
          </NavLink>
        ))}
      </List>
    </Box>
  );

  // Mobile
  return (
    <>
      <AppBar position="static" sx={{ zIndex: 1 }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box
              component="img"
              sx={{
                width: 32,
                mr: 1,
                display: { xs: 'none', md: 'flex' },
              }}
              alt="stefan festes logo"
              src="/assets/logo-stroke.png"
            />
            <Typography
              variant="h5"
              noWrap
              component={NavLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'Raleway',
                fontWeight: 400,
                letterSpacing: '.1rem',
                color: grey[100],
                textDecoration: 'none',
              }}
            >
              Stefán Festés
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                sx={{ color: grey[100] }}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box
              component="img"
              sx={{
                width: 32,
                mr: 1,
                display: { xs: 'flex', md: 'none' },
              }}
              alt="stefan festes logo"
              src="/assets/logo-stroke.png"
            />
            <Typography
              variant="h5"
              noWrap
              component={NavLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'Raleway',
                fontWeight: 400,
                letterSpacing: '.1rem',
                color: grey[100],
                textDecoration: 'none',
              }}
            >
              Stefán Festés
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                gap: 1,
                justifyContent: 'flex-end',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {Object.keys(pages).map((page) => (
                <NavLink to={page} key={page}>
                  {({ isActive }) => (
                    <Button
                      sx={{
                        my: 2,
                        color: grey[100],
                        display: 'block',
                        fontWeight: 400,
                        '&:hover': { backgroundColor: grey[800] },
                      }}
                      style={isActive ? activeStyle : undefined}
                    >
                      {pages[page as keyof typeof pages]}
                    </Button>
                  )}
                </NavLink>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <SwipeableDrawer
        anchor="left"
        disableDiscovery
        disableSwipeToOpen
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {renderMobileMenu()}
      </SwipeableDrawer>
    </>
  );
}

export default Header;
