import React, { useEffect, useRef, useState } from 'react';
import { Slide, Box, SlideProps } from '@mui/material';

interface SlideInBoxProps extends SlideProps {
  children: JSX.Element;
}

const SlideInBox: React.FC<SlideInBoxProps> = ({
  children,
  direction = 'right',
  timeout = 500,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const rect = boxRef.current?.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Check if the box is in the viewport
      const isVisibleNow = rect && rect.top <= windowHeight && rect.bottom >= 0;

      if (isVisibleNow) {
        setIsVisible(true);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Initial check when the component mounts
    handleScroll();

    // Clean up the event listener when the component is unmounted
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Slide direction={direction} in={isVisible} timeout={timeout}>
      <Box ref={boxRef} {...props}>
        {children}
      </Box>
    </Slide>
  );
};

export default SlideInBox;
