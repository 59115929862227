import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import ImageGallery from '../components/ImageGallery';

export function Paint() {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 6 }}>
        <Typography variant="h2" align="center" component="h1" gutterBottom>
          Festés, tapétázás és gipszkartonozás
        </Typography>
      </Container>

      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}>
            <Box
              component="img"
              sx={{
                maxHeight: 500,
                objectFit: 'contain',
                objectPosition: { sm: 'center' },
                width: '100%',
                height: { xs: '100%', sm: 500, md: 400 },
              }}
              src="assets/images/festes/IMG_3308.webp"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <Container>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h6" paragraph>
                    Szobafestés:
                  </Typography>
                  <ol color="text.secondary">
                    <li>
                      <Typography variant="body1">
                        <strong>Előkészítés:</strong> A helyiség előkészítése,
                        például bútorok eltávolítása vagy lefedése, falak
                        tisztítása és javítások elvégzése.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Alapozás:</strong> Az alapozó réteg felvitele a
                        festőmunka előtt a festék jobb tapadásáért.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Festés:</strong> A kiválasztott szín felvitele
                      </Typography>
                    </li>
                  </ol>
                </Box>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h6" paragraph>
                    Szobafestés Előnyei:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        <strong>Frissítő Megjelenés:</strong> A szobafestés
                        segít frissíteni és megújítani a helyiség megjelenését.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Egyedi Stílus:</strong> Különböző színek és
                        minták lehetőséget adnak az egyedi stílus kifejezésére.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Gyors Kivitelezés:</strong> A szobafestés
                        általában gyorsabb más burkolási eljárásokhoz képest.
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}>
            <Box
              component="img"
              sx={{
                maxHeight: 500,
                objectFit: 'contain',
                objectPosition: { sm: 'center' },
                width: '100%',
                height: { xs: '100%', sm: 500, md: 400 },
              }}
              src="assets/images/festes/IMG_0613.webp"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <Container>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h6" paragraph>
                    Tapétázás:
                  </Typography>
                  <ol>
                    <li>
                      <Typography variant="body1">
                        <strong>Előkészítés:</strong> A helyiség előkészítése, a
                        falak simaságának ellenőrzése és szükség esetén
                        javítások elvégzése.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Tapéta Kiválasztása:</strong> A megfelelő tapéta
                        kiválasztása a tervezett stílus és funkció szerint.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Tapéta Ragasztása:</strong> A tapéta ragasztó
                        felvitele a tapétára és a falra, majd a tapéta pontos
                        illesztése.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Vágás és Kiegyenlítés:</strong> A felesleges
                        tapéta levágása és az illesztések kiegyenlítése után a
                        tapéta szárad.
                      </Typography>
                    </li>
                  </ol>
                </Box>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h6" paragraph>
                    Tapétázás Előnyei:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        <strong>Variabilitás:</strong> Sokféle stílus, minta és
                        textúra választható.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Könnyen cserélhető:</strong> Sok modern tapéta
                        könnyen eltávolítható, így könnyen cserélhető.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Falhibák Elrejtése:</strong> A tapéta könnyen
                        elrejti a kisebb falhibákat és egyenetlenségeket.
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}>
            <Box
              component="img"
              sx={{
                maxHeight: 500,
                objectFit: 'contain',
                objectPosition: { sm: 'center' },
                width: '100%',
                height: { xs: '100%', sm: 500, md: 400 },
              }}
              src="assets/images/festes/IMG_3308.webp"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <Container>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h6" paragraph>
                    Gipszkartonozás:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1">
                    A gipszkartonozás modern és hatékony építészeti megoldás,
                    amely gyors kivitelezési időt, könnyű súlyt és rugalmas
                    tervezési lehetőségeket kínál. A falak és mennyezetek
                    kialakításához alkalmazott fém vagy fa keretek segítik a
                    stabil szerkezet kialakítását. A gipszkarton lapok könnyen
                    rögzíthetők és módosíthatók, lehetővé téve az épület belső
                    térának rugalmas alakítását. Emellett a gipszkarton anyaga
                    enyhe hang- és hőszigetelést nyújt, javítva az épület
                    kényelmét és hatékonyságát. A gipszkartonozás
                    költséghatékony és népszerű választás a lakó- és
                    kereskedelmi épületek belső kialakításában.
                  </Typography>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ py: 4 }}>
        <ImageGallery
          images={[
            'assets/images/festes/IMG_0545.webp',
            'assets/images/festes/IMG_0547.webp',
            'assets/images/festes/IMG_0561.webp',
            'assets/images/festes/IMG_0575.webp',
            'assets/images/festes/IMG_0592.webp',
            'assets/images/festes/IMG_0594.webp',
            'assets/images/festes/IMG_0597.webp',
            'assets/images/festes/IMG_0600.webp',
            'assets/images/festes/IMG_0604.webp',
            'assets/images/festes/IMG_0606.webp',
            'assets/images/festes/IMG_0613.webp',
            'assets/images/festes/IMG_1243.webp',
            'assets/images/festes/IMG_1269.webp',
            'assets/images/festes/IMG_1282.webp',
            'assets/images/festes/IMG_1284.webp',
            'assets/images/festes/IMG_1287.webp',
            'assets/images/festes/IMG_1244.webp',
            'assets/images/festes/IMG_1414.webp',
            'assets/images/festes/IMG_1637.webp',
            'assets/images/festes/IMG_1658.webp',
            'assets/images/festes/IMG_1661.webp',
            'assets/images/festes/IMG_1662.webp',
            'assets/images/festes/IMG_1272.webp',
            'assets/images/festes/IMG_1274.webp',
            'assets/images/festes/IMG_1800.webp',
            'assets/images/festes/IMG_1806.webp',
            'assets/images/festes/IMG_1807.webp',
            'assets/images/festes/IMG_1844.webp',
            'assets/images/festes/IMG_1846.webp',
            'assets/images/festes/IMG_1847.webp',
            'assets/images/festes/IMG_1850.webp',
            'assets/images/festes/IMG_1923.webp',
            'assets/images/festes/IMG_1930.webp',
            'assets/images/festes/IMG_1932.webp',
            'assets/images/festes/IMG_1934.webp',
            'assets/images/festes/IMG_1973.webp',
            'assets/images/festes/IMG_1974.webp',
            'assets/images/festes/IMG_1975.webp',
            'assets/images/festes/IMG_1977.webp',
            'assets/images/festes/IMG_1979.webp',
            'assets/images/festes/IMG_1980.webp',
            'assets/images/festes/IMG_1981.webp',
            'assets/images/festes/IMG_1982.webp',
            'assets/images/festes/IMG_1985.webp',
            'assets/images/festes/IMG_1986.webp',
            'assets/images/festes/IMG_2060.webp',
            'assets/images/festes/IMG_2067.webp',
            'assets/images/festes/IMG_2069.webp',
            'assets/images/festes/IMG_2071.webp',
            'assets/images/festes/IMG_2074.webp',
            'assets/images/festes/IMG_3013.webp',
            'assets/images/festes/IMG_3014.webp',
            'assets/images/festes/IMG_3080.webp',
            'assets/images/festes/IMG_3081.webp',
            'assets/images/festes/IMG_3083.webp',
            'assets/images/festes/IMG_3097.webp',
            'assets/images/festes/IMG_3099.webp',
            'assets/images/festes/IMG_3129.webp',
            'assets/images/festes/IMG_3231.webp',
            'assets/images/festes/IMG_3235.webp',
            'assets/images/festes/IMG_3237.webp',
            'assets/images/festes/IMG_3238.webp',
            'assets/images/festes/IMG_3303.webp',
            'assets/images/festes/IMG_3304.webp',
            'assets/images/festes/IMG_3308.webp',
            'assets/images/festes/IMG_3515.webp',
            'assets/images/festes/IMG_3518.webp',
            'assets/images/festes/IMG_3519.webp',
            'assets/images/festes/IMG_3608.webp',
            'assets/images/festes/IMG_3620.webp',
            'assets/images/festes/IMG_3624.webp',
            'assets/images/festes/IMG_3632.webp',
            'assets/images/festes/IMG_3798.webp',
            'assets/images/festes/IMG_3799.webp',
            'assets/images/festes/IMG_3801.webp',
            'assets/images/festes/IMG_3802.webp',
            'assets/images/festes/IMG_3803.webp',
            'assets/images/festes/IMG_3804.webp',
            'assets/images/festes/IMG_3807.webp',
            'assets/images/festes/IMG_3812.webp',
            'assets/images/festes/IMG_3815.webp',
            'assets/images/festes/IMG_3816.webp',
            'assets/images/festes/IMG_3837.webp',
            'assets/images/festes/IMG_3855.webp',
            'assets/images/festes/IMG_3856.webp',
            'assets/images/festes/IMG_3857.webp',
            'assets/images/festes/IMG_3858.webp',
            'assets/images/festes/IMG_3860.webp',
            'assets/images/festes/IMG_3863.webp',
            'assets/images/festes/IMG_3864.webp',
            'assets/images/festes/IMG_3871.webp',
            'assets/images/festes/IMG_3872.webp',
            'assets/images/festes/IMG_3877.webp',
            'assets/images/festes/IMG_3878.webp',
            'assets/images/festes/IMG_3896.webp',
            'assets/images/festes/IMG_3905.webp',
            'assets/images/festes/IMG_4196.webp',
            'assets/images/festes/IMG_4197.webp',
            'assets/images/festes/IMG_2041.jpg',
            'assets/images/festes/IMG_2047.jpg',
          ]}
        />
      </Container>
    </>
  );
}
