import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import ImageGallery from '../components/ImageGallery';

export function Floorlaying() {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 6 }}>
        <Typography variant="h2" align="center" component="h1" gutterBottom>
          Burkolás
        </Typography>
      </Container>

      <Container sx={{ py: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}>
            <Box
              component="img"
              sx={{
                maxHeight: 500,
                objectFit: 'contain',
                objectPosition: { sm: 'center' },
                width: '100%',
                height: { xs: '100%', sm: 500, md: 400 },
              }}
              src="/assets/images/burkolas/IMG_2891.webp"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <Container>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h6" paragraph>
                    Burkolás:
                  </Typography>
                  <ol>
                    <li>
                      <Typography variant="body1">
                        <strong>Alapfelület előkészítése:</strong> A felület
                        tisztítása, simítása, és szükség esetén javítása.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Ragasztó felvitele:</strong> Burkoló ragasztó
                        felvitele a felületre a csempék vagy a burkolólapok
                        tapadásához.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Burkolólapok elhelyezése:</strong> A
                        kiválasztott burkolólapok vagy csempék pontos
                        elrendezése a felületen.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Illesztések és vágások:</strong> Az illesztések
                        és a szükséges vágások elkészítése a megfelelő
                        burkolólapok kialakításához.
                      </Typography>
                    </li>
                  </ol>
                </Box>
                <Box sx={{ py: 4 }}>
                  <Typography variant="h6" paragraph>
                    Burkolás előnyei:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        <strong>Esztétikai megjelenés:</strong> A burkolás segít
                        esztétikailag kialakítani a felületet különböző
                        csempékkel vagy burkolólapokkal.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Praktikus tisztántarthatóság:</strong> A
                        burkolás segít a könnyű tisztántarthatóságban, különösen
                        a fürdőszobákban és konyhákban.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Vízállóság:</strong> A megfelelő burkolás
                        vízállóságot biztosít, különösen nedves környezetekben.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Tartósság:</strong> A burkolás hozzájárul a
                        falak és padlók tartósságához.
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ py: 4 }}>
        <ImageGallery
          images={[
            '/assets/images/burkolas/IMG_2827.webp',
            '/assets/images/burkolas/IMG_2833.webp',
            '/assets/images/burkolas/IMG_2835.webp',
            '/assets/images/burkolas/IMG_2844.webp',
            '/assets/images/burkolas/IMG_2846.webp',
            '/assets/images/burkolas/IMG_2853.webp',
            '/assets/images/burkolas/IMG_2859.webp',
            '/assets/images/burkolas/IMG_2863.webp',
            '/assets/images/burkolas/IMG_2878.webp',
            '/assets/images/burkolas/IMG_2879.webp',
            '/assets/images/burkolas/IMG_2891.webp',
            '/assets/images/burkolas/IMG_2929.webp',
            '/assets/images/burkolas/IMG_2935.webp',
            '/assets/images/burkolas/IMG_2464.jpg',
            '/assets/images/burkolas/IMG_2469.jpg',
            '/assets/images/burkolas/IMG_2501.jpg',
            '/assets/images/burkolas/IMG_2505.jpg',
            '/assets/images/burkolas/IMG_2511.jpg',
            '/assets/images/burkolas/IMG_2512.jpg',
          ]}
        />
      </Container>
    </>
  );
}
