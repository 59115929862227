import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function ServiceItem({
  imageSrc,
  title,
  description,
}: {
  imageSrc: string;
  title: string;
  description: string;
}) {
  return (
    <Card
      sx={{
        maxWidth: { sm: '100%', md: 345 },
        boxShadow: 0,
        ':hover': { boxShadow: 1 },
        ':active': { boxShadow: 0 },
        border: '1px solid',
        borderColor: grey[300],
      }}
    >
      <CardActionArea>
        <CardMedia component="img" width={'100%'} image={imageSrc} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
